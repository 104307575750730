.App {
    min-height: 100vh;
}

.hover-shadow {
    cursor: pointer;
}

.hover-shadow:hover{
    box-shadow:0 .5rem 1rem rgba(0,0,0,.15) !important;
}

.login-block, .register-block, .big-status-block {
    background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(https://images7.alphacoders.com/328/328672.jpg) no-repeat center center / cover;
    cursor: pointer;
    height: 100px;
    width: 100%;
}

.register-block{
    height: 200px;
}

.big-status-block{
    background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(https://images3.alphacoders.com/106/1062607.jpg) no-repeat center center / cover;
    height: 50vh;
}

.no-text-decor{
    text-decoration: none;
    color: inherit;
    cursor: pointer !important;
}

.no-text-decor:hover{
    text-decoration: none;
    color: inherit;
}

.img-object-fit-cover {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.rounded-right-0 {
    border-top-right-radius:0 !important;
    border-bottom-right-radius: 0 !important;
}

.input-top {
    position: absolute;
    top: -12px;
    left: 12px;
    background: white;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 14px;
    font-weight: bold;
    color: #6c757d;
}

.form-control:-webkit-autofill {
    -webkit-background-clip: text;
}

.fs-12 {
    font-size: 12px;
}

.transition-medium {
    transition: 0.34s;
}